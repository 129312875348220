const IntegrationData = {

    handleSubtitle : function ( name ){

        let subtitle = ""
        name == 'Vtex' ? subtitle = "The end-to-end platform with unlimited customization and extension capabilities." : ''
        name == 'Fenicio' ? subtitle = 'Cloud platform for managing e-commerce sales channels.' : ''
        name == 'Magento1' ? subtitle = 'Open Source eCommerce to create your webshop.' : ''
        name == 'Magento2' ? subtitle = 'Open Source eCommerce to create your webshop.' : ''
        name == 'Prestashop' ? subtitle = 'PrestaShop is a free, open source e-commerce solution.' : ''
        name == 'WooCommerce' ? subtitle = 'The most popular eCommerce platform on the web.' : ''
        name == 'Tienda Nube' ? subtitle = 'Creator of online stores without difficulties, with accessible prices.' : ''
        return subtitle

    },

}
export default IntegrationData
