<template>
  <div>
    <WrapperChannels @search="searchChannel" />
    <div>
      <TransitionFade>
        <div>
          <div class="container-xl px-2 py-2 mx-auto w-full mt-4 mb-16 flex flex-col">
            <MultipleCardSkeleton v-if="loading" />
            <div v-if="channels.length">
              <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4">
                <Card v-for="channel of channels" :key="channel.id">
                  <template #content>
                    <div class="flex flex-col space-y-6">
                      <div class="flex-center justify-between">
                        <div class="flex-center space-x-3">
                          <img :src="channel.avatar_short" :alt="`${channel.name} logo`" class="w-10 h-10 object-contain" />
                          <div class="font-semibold ml-2 text-gray-600 block">{{ channel.name }}</div>
                        </div>
                      </div>
                      <div class="flex-center justify-start">
                        <Button v-if="channel.existCredential" type="button" icon="fas fa-cog" iconPos="left" @click="$router.push(`/project/${$route.params.id}/channel/connections/${channel.id}`)" :label="$t('integrations.buttonConfig')" class="p-button-sm p-button-light shadow" />
                        <Button v-else type="button" icon="fas fa-plug" iconPos="left" @click="$router.push(`/project/${$route.params.id}/channel/connections/${channel.id}`)" :label="$t('integrations.buttonIntegrate')" class="p-button-sm p-button-primary shadow" />
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
            <div v-if="channels.length === 0 && !loading" class="text-center block mx-auto my-20">
              <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto mb-6 text-gray-600" width="96" height="96" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
              </svg>
              <h3 class="text-gray-600 text-2xl font-semibold my-2">{{ $t("dashboard.noResults") }}</h3>
              <p class="text-gray-500 text-xl font-semibld my-2">{{ $t("audience.filter") }}</p>
            </div>
          </div>
        </div>
      </TransitionFade>
    </div>
  </div>
</template>
<script>
import axiosClient from "@/config/AxiosClient"
import TransitionFade from "@/components/TransitionFade"
import IntegrationData from "@/config/IntegrationsConfig"
import WrapperChannels from "@/components/UI/wrappers/WrapperChannels"
import MultipleCardSkeleton from "@/components/UI/MultipleCardSkeleton"

import Card from "primevue/card"
import Button from "primevue/button"

export default {
  components: {
    MultipleCardSkeleton,
    TransitionFade,
    WrapperChannels,
    Card,
    Button
  },
  name: `Channels`,
  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      filterKeyword: "",
      sort: "id",
      limit: 50,
      default: {
        sort: "id",
        limit: 50
      },
      channels: [],
      channelsAll: [],
      stateFilter: 0,
      statics_integrations: {},
      loading: true,
    }
  },
  computed: {
    getActiveProject() {
      return this.$store.state.Projects.activeProject
    }
  },
  methods: {
    subTitle: function (name) {
      return IntegrationData.handleSubtitle(name)
    },
    searchChannel: function (value) {
      this.filterKeyword = value
      let regex = new RegExp(value, "i");
      this.channels = this.channelsAll.filter(f => regex.test(f.name))
    },
    getChannels: async function () {
      let currentProject = this.currentProject || {}
      let filter = {}
      if (this.filterKeyword) filter["filter[name][like]"] = `%${this.filterKeyword}%`
      await this.$store.dispatch("Channels/getChannels", { currentProject, filter })
    },
  },
  async created() {
    await this.getChannels()
    this.channels = this.$store.state.Channels.channels
    let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.getActiveProject.id}/credentials?onlyChannel=1`)

    this.channels.forEach((channel) => {
      channel.existCredential = response.data.includes(channel.id) != false
    })

    this.channelsAll = this.channels
    this.loading = false
  }
}
</script>
